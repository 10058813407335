import { EventCode } from "@coderone/library";
import { List } from "@fluentui/react";
import * as React from "react";
import { useCallback, useMemo, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import {
    ISubmissionLeaderBoardRanking,
    useSubmissionTrueskillLeaderboard,
} from "../../../../../hooks/api/useSubmissionTrueskillLeaderboard";
import { getRelativeTime } from "../../../../../utilities/getRelativeTime";
import { Spinner } from "../../../../Spinner/Spinner";
import { CountryCode } from "../../Account/CountryPicker/Country.types";
import { CountryFlagSVG } from "../../Account/CountryPicker/CountryFlagSVG";
import { LeaderboardHeader } from "../LeaderboardHeader/LeaderboardHeader";
import { Cell, ResultRow, TeamBlock, SeasonNavigationContainer, SeasonLink, SeasonLinkStyledOverride, SettingsContainer } from "./LeaderboardRankings.styles";
import { CoderOneRoute } from "../../../../Pages/CoderOneRoute";
import { TelemetryLink } from "../../../../TelemetryLink/TelemetryLink";
import { useTeam } from "../../../../../hooks/api/team/useTeam";
import { Checkbox } from "../../../../Checkbox/Checkbox";
import { InfoBar } from "../../../../InfoBar/InfoBar";

interface IProps {
    readonly eventCode: EventCode;
}

export const LeaderboardRankings: React.FC<React.PropsWithChildren<IProps>> = ({ eventCode }) => {
    const [t] = useTranslation();
    const { data: submissionData } = useSubmissionTrueskillLeaderboard(eventCode);
    const { data: teamData } = useTeam();

    const usersTeamId = teamData?.team._id;

    const [shouldShowOnlyTop, setShouldShowOnlyTop] = useState<boolean>(true);
    const filteredLeaderboard = useMemo(() => {
        if (shouldShowOnlyTop === false) {
            return submissionData;
        }
        const seenSet = new Set<string>();
        return (submissionData ?? []).filter((ranking) => {
            if (seenSet.has(ranking.name)) {
                return false;
            } else {
                seenSet.add(ranking.name);
                return true;
            }
        });
    }, [submissionData, shouldShowOnlyTop]);

    const onShowOnlyTopToggled = useCallback(
        (_: unknown, checked?: boolean | undefined) => {
            setShouldShowOnlyTop(checked ?? false);
        },
        [setShouldShowOnlyTop]
    );

    const onRenderCell = useCallback((item?: ISubmissionLeaderBoardRanking, index?: number | undefined) => {
        if (item === undefined || index === undefined) {
            return null;
        }
        const { name, mu, lastUpdated, sigma, alias, country, teamId } = item;
        const isUsersTeam = teamId === usersTeamId;
        const rank = index + 1;

        return (
            <ResultRow className={isUsersTeam ? `emphasisRow` : ``}>
                <Cell>{rank}</Cell>
                <Cell>
                    <TeamBlock>
                        <CountryFlagSVG code={CountryCode[country]} /> {name}
                    </TeamBlock>
                </Cell>
                <Cell>{alias}</Cell>
                <Cell>
                    {mu.toFixed(2)} ± {sigma.toFixed(2)}
                </Cell>
                <Cell>{getRelativeTime(t, lastUpdated)}</Cell>
            </ResultRow>
        );
    }, []);
    if (submissionData === undefined) {
        return <Spinner />;
    }
    return (
        <React.Fragment>
            <SettingsContainer>
                <Checkbox checked={shouldShowOnlyTop} onChange={onShowOnlyTopToggled} label={t("onlyShowTopTeamSubmissions")} />
                <SeasonNavigationContainer>
                    <SeasonLink to={CoderOneRoute.Leaderboard} activeClassName="active">Season 4</SeasonLink>
                    <SeasonLink to={CoderOneRoute.LeaderboardV3} activeClassName="active">Season 3</SeasonLink>
                    <TelemetryLink href={CoderOneRoute.BlogSeasonTwoRecap} target="_blank" styledOverride={SeasonLinkStyledOverride}>Season 2</TelemetryLink>
                    <TelemetryLink href={CoderOneRoute.BlogSeasonOneRecap} target="_blank" styledOverride={SeasonLinkStyledOverride}>Season 1</TelemetryLink>
                </SeasonNavigationContainer>
            </SettingsContainer>
            { eventCode == EventCode.BomberlandV4 &&
                <InfoBar>
                    <span>
                        <Trans key="leaderboard.note">
                            Congratulations to our Season 4 champions: Igglysplat! Learn more in our <TelemetryLink href={CoderOneRoute.BlogSeasonFourRecap}>Competition Recap</TelemetryLink>. You can still make new submissions to the leaderboard.
                        </Trans>
                    </span>
                </InfoBar>
            }
            <LeaderboardHeader />
            <List items={filteredLeaderboard} onRenderCell={onRenderCell} onShouldVirtualize={() => false} />
        </React.Fragment>
    );
};
