import styled from "styled-components";
import { Palette } from "../../../../../theme/Palette";
import { Link } from "gatsby";
import { Viewport } from "../../../../../utilities/Constants/Viewport";

interface IStatusProps {
    readonly status: "W" | "T" | "L";
}

const getColour = (status: "W" | "T" | "L") => {
    if (status === "W") {
        return Palette.Success100;
    } else if (status === "T") {
        return Palette.Primary100;
    } else {
        return Palette.Error100;
    }
};

export const Data = styled("div")<IStatusProps>`
    box-sizing: border-box;
    color: ${({ status }) => getColour(status)};
    font-weight: 600;
    font-size: 14px;
`;

export const ResultRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr;
    grid-column-gap: 8px;
    padding: 16px;
    text-align: center;
    border-bottom: 1px solid ${Palette.Neutral20};
    border-radius: 4px;
    transition: transform 200ms ease-in-out;

    :hover {
        background: ${Palette.Neutral10};
    }
    
    &.emphasisRow {
        background: linear-gradient(90deg, ${Palette.Primary100}, ${Palette.Accent100} 100%);
        color: ${Palette.White100};
        border: none;
    }
`;

export const List = styled.table`
`;

export const Cell = styled.div`
    display: flex;
    justify-content: center;
`;

export const TeamBlock = styled.div`
    display: grid;
    grid-template-columns: auto auto;
    grid-column-gap: 8px;
`;

export const SeasonNavigationContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const SeasonLink = styled(Link)`
    text-decoration: none;
    padding: 4px 8px;
    text-align: center;
    border: 1px solid ${Palette.Primary100};
    transition: background-color 150ms;
    color: ${Palette.Primary100};
    margin-left: -1px;
    
    :first-child {
        border-radius: 4px 0 0 4px;
    }

    :last-child {
        border-radius: 0 4px 4px 0;
    }

    :hover {
        background-color: ${Palette.White10};
    }

    &.active {
        background-color: ${Palette.Primary100};
        color: ${Palette.White100};
    }
`;

export const SeasonLinkStyledOverride = styled.a`
    text-decoration: none;
    padding: 4px 8px;
    text-align: center;
    border: 1px solid ${Palette.Primary100};
    transition: background-color 150ms;
    color: ${Palette.Primary100};
    margin-left: -1px;

    :first-child {
        border-radius: 4px 0 0 4px;
    }

    :last-child {
        border-radius: 0 4px 4px 0;
    }

    :hover {
        background-color: ${Palette.White10};
    }

    &.active {
        background-color: ${Palette.Primary100};
        color: ${Palette.White100};
    }
`;

export const SettingsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: ${Viewport.Medium}px) {
        flex-direction: column;
        gap: 16px;
    }
`;