import { EventCode } from "@coderone/library";
import * as React from "react";
import { ContentCard } from "../../../ContentCard/ContentCard";
import { Root } from "./LeaderboardContent.styles";
import { LeaderboardRankings } from "./LeaderboardRankings/LeaderboardRankings";


interface IProps {
    readonly displayInfoBar?: boolean;
    readonly eventCode: EventCode;
}

export const LeaderboardContent: React.FC<React.PropsWithChildren<IProps>> = ({ eventCode }) => {
    return (
        <Root>
            <ContentCard maxWidth="none" overflow="auto" >
                <LeaderboardRankings eventCode={eventCode} />
            </ContentCard>
        </Root>
    );
};
