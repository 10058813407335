import * as React from "react";
import { useCallback } from "react";
import { Root, Label } from "./Checkbox.styles";

interface IProps {
    readonly label?: string | undefined;
    readonly checked: boolean;
    readonly onChange: (_: unknown, checked?: boolean | undefined) => void;
}

export const Checkbox: React.FC<React.PropsWithChildren<IProps>> = ({ label, checked, onChange }) => {
    const onCheckedChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            const formValue = ev.target.checked;
            onChange?.(ev, formValue);
        },
        [onChange]
    );

    return (
        <Root>
            <input type="checkbox" checked={checked} onChange={onCheckedChange} />
            {label && <Label>{label}</Label>}
        </Root>
    );
};
