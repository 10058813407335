import * as React from "react";
import { useTranslation } from "react-i18next";
import { HeaderRow, HeaderText } from "./LeaderboardHeader.styles";

export const LeaderboardHeader: React.FC<React.PropsWithChildren<unknown>> = () => {
    const [t] = useTranslation();
    return (
        <React.Fragment>
            <HeaderRow>
                <HeaderText>{t("rank")}</HeaderText>
                <HeaderText>{t("team")}</HeaderText>
                <HeaderText>{t("alias")}</HeaderText>
                <HeaderText>{t("leaderboard.rating")}</HeaderText>
                <HeaderText>{t("lastUpdated")}</HeaderText>
            </HeaderRow>
        </React.Fragment>
    );
};
