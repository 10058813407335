import styled from "styled-components";

export const Root = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
`;

export const Label = styled.span``;
