import * as React from "react";
import { useTranslation } from "react-i18next";
import { AuthenticatedFrame } from "../../../AuthenticatedFrame/AuthenticatedFrame";
import { WithoutAuth } from "../../../Auth/WithoutAuth";
import { SEO } from "../../../SEO/SEO";
import { LeaderboardContent } from "./LeaderboardContent";
import { NavigationHeader } from "../../../NavigationHeader/NavigationHeader";
import { Root } from "./Leaderboard.styles";
import { Footer } from "../../../Footer/Footer";
import { EventCode } from "@coderone/library";

interface IProps {
    readonly eventCode: EventCode;
}

export const Leaderboard: React.FC<React.PropsWithChildren<IProps>> = ({ eventCode }) => {
    const [t] = useTranslation();
    const title = t("leaderboard.heading");
    const description = t("leaderboard.heading");
    const Fallback = (
        <AuthenticatedFrame title={t("leaderboard.heading")}>
            <LeaderboardContent eventCode={eventCode} displayInfoBar={true} />
        </AuthenticatedFrame>
    );

    return (
        <React.Fragment>
            <SEO description={description} title={title} />
            <WithoutAuth fallback={Fallback}>
                <NavigationHeader />
                <Root>
                    <LeaderboardContent eventCode={eventCode} displayInfoBar={true} />
                </Root>
                <Footer />
            </WithoutAuth>
        </React.Fragment>
    );
};
