import styled from "styled-components";
import { Palette } from "../../../../theme/Palette";

export const Root = styled.div`
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
`;

export const LeaderboardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-color: ${Palette.Neutral20};
    border-style: solid;
`;

export const RankingsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-color: ${Palette.Neutral20};
    border-style: solid;
    border-width: 1px 1px 0px 1px;
`;

export const ContentWrapper = styled.div`
    max-width: 600px;
`;
