import styled from "styled-components";

export const HeaderRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 2fr 2fr;
    padding: 16px;
    text-align: center;
    grid-column-gap: 8px;
`;

export const HeaderText = styled.span`
    font-weight: 700;
`;
