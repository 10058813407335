import axios, { AxiosError } from "axios";
import { AuthContext } from "../../../components/Auth/AuthContext";
import { getAxiosRequestConfig } from "../../../utilities/getAxiosRequestConfig";
import { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import { Constants } from "../../../utilities/Constants";

interface ITeamResponse {
    readonly team: {
        readonly _id: string;
        readonly modified: string;
        readonly _ownerId: string;
        readonly name: string;
        readonly revision: number;
        readonly _userId: string;
    };
    readonly memberCount: number;
    readonly members: Array<string>;
    readonly owner: string;
}

const getTeamById = async (token: string | null, teamId?: string) => {
    if (token === null) {
        return null;
    }
    const config = getAxiosRequestConfig(token);

    const uri = teamId === undefined ? `${Constants.ApiRoot}/team` : `${Constants.ApiRoot}/team/${teamId}`;
    try {
        const { data } = await axios.get<ITeamResponse>(uri, config);
        return data;
    } catch (error) {
        const axiosError = error as AxiosError;
        if (axiosError?.response?.status === 404) {
            return null;
        }
        throw error;
    }
};

export const useTeam = (userId?: string) => {
    const { token } = useContext(AuthContext);

    return useQuery(["team", userId, token], () => getTeamById(token, userId));
};
